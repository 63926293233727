import { Popup } from "@progress/kendo-react-popup";
import * as React from "react";
import labatonLogo from "../../assets/images/client-logo.png";
import "../../component/popup/popup.scss";

interface IAlertProps {
  component?: React.ReactNode;
  longText?: string | React.ReactNode;
  actionButtonText: string;
  isVisible?: boolean;
  onClose: Function;
}

const Alert = React.memo(
  ({ component, longText, actionButtonText, onClose, isVisible = true }: IAlertProps): JSX.Element => {
    const anchor = React.useRef<HTMLButtonElement | null>(null);
    const [show, setShow] = React.useState(false);

    React.useEffect(() => {
      setShow(isVisible);
    }, [isVisible]);

    const onClick = () => {
      onClose();
      setShow(false);
    };
    return (
      <div>
        <Popup
          className="custom-popup login-popup"
          anchor={anchor.current}
          show={show}
          popupClass={"popup-content"}
        >
          {component ? (
            component
          ) : (
            <>
              <div className="main-logo mt-20">
                <img
                  src={labatonLogo}
                  alt="labaton logo"
                />
              </div>
              <h1 className="big-heading text-primary mb-0">WELCOME TO CLIENT CONNECT</h1>
              <h3 className="sub-heading text-primary font-400">BY LABATON KELLER SUCHAROW</h3>
              <div className="details text-primary">{longText}</div>
              <button
                className="custom-btn custom-primary-btn"
                onClick={onClick}
                ref={anchor}
              >
                {actionButtonText}
              </button>
            </>
          )}
        </Popup>
      </div>
    );
  }
);

export default Alert;
