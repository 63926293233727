import React from "react";
import Alert from "../component/popup";
import { LOCAL_STORAGE_KEYS } from "../utils/enums";
import { CacheManager, setOverflowStyleInBody } from "../utils/helper";
import { useAuth } from "./AuthProvider";

interface IUserConsentProps {
  children: React.ReactNode;
}

interface IUserConsentContextType {}
const UserConsentContext = React.createContext<IUserConsentContextType | undefined>(undefined);
const UserConsentProvider: React.FC<IUserConsentProps> = ({ children }) => {
  const auth = useAuth();
  const [isVisible, setIsVisible] = React.useState<boolean>(false);
  React.useEffect(() => {
    const isEnabled = CacheManager.getItem(LOCAL_STORAGE_KEYS.LOGIN_SUCCESS_ALERT);
    if (auth.isValidUser === false) {
      setOverflowStyleInBody(!auth.isValidUser);
      setIsVisible(!auth.isValidUser);
    } else {
      setOverflowStyleInBody(isEnabled);
      setIsVisible(!isEnabled);
    }
  }, [auth]);
  const userConsentValue: IUserConsentContextType = {};
  return (
    <UserConsentContext.Provider value={userConsentValue}>
      <Alert
        onClose={() => {
          if (auth.isValidUser === false) {
            auth.logout();
          } else {
            CacheManager.setItem(LOCAL_STORAGE_KEYS.LOGIN_SUCCESS_ALERT, "true");
            setOverflowStyleInBody(true);
            setIsVisible(false);
          }
        }}
        isVisible={isVisible}
        longText={
          <>
            {auth.isValidUser ? (
              <>
                <p className="mb-20">
                  THIS SYSTEM AND ITS CONTENT AND FUNCTIONALITY IS FOR LABATON KELLER SUCHAROW LLP (“THE FIRM”) AUTHORIZED
                  USERS ONLY. IT IS PRIVATE, PROPRIETARY, AND CONFIDENTIAL IT MAY ONLY BE USED FOR AUTHORIZED PURPOSES
                  IN ACCORDANCE WITH THE TERMS OF USE, END USER LICENSE AGREEMENT (EULA), AND FIRM POLICIES. ANY
                  UNAUTHORIZED USE OF THIS SYSTEM MAY RESULT IN CIVIL OR CRIMINAL PENALTIES. THE FIRM MAY MONITOR
                  EVERYTHING YOU SEND, CREATE, STORE, SHARE, PROCESS, OR RECEIVE ON IT.
                </p>
                <p> BY CLICKING OK, YOU AGREE TO THIS NOTICE.</p>
              </>
            ) : (
              <p className="mb-20">{auth?.invalidUserMessage}</p>
            )}
          </>
        }
        actionButtonText={auth.isValidUser === false ? "SIGN OUT" : "OKAY, I AGREE"}
      />
      {!isVisible && children}
    </UserConsentContext.Provider>
  );
};

const useUserConsent = () => {
  const context = React.useContext(UserConsentContext);
  if (context === undefined) {
    throw new Error("useUserConsent must be used within an UserConsentProvider");
  }
  return context;
};
export { UserConsentProvider, useUserConsent };
